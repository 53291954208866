import React from 'react';
import styled, { css } from 'styled-components';
import { PageLayoutComponent } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { PageLayoutStandard } from '@shapeable/ui';

// -------- Styles -------->

const LayoutStyles = breakpoints({
  base: css`

    /* override title styles via BEM here to affect spacing */ 
    .shp--page-layout-standard__title {
      color: ${theme.COLOR('light')};
      letter-spacing: -1px;
      font-weight: 400;
      font-size: 2.5em;
      font-family: ${theme.FONT('heading')};
      max-width: 400px;
      line-height: 0.9em;
      
    }
  `,
  tablet: css`
    .shp--page-layout-standard__title {
      font-size: 4.5em;
    }

  `,
  desktop: css`
  .shp--page-layout-standard__title {
    font-size: 5.5em;
  }
  `
});

// -------- Components -------->

const My = {
  Layout: styled(PageLayoutStandard)`${LayoutStyles}`,
};

export const PageLayoutHome: PageLayoutComponent = (props) => {
  const { className, entity, slices } = props;

  return (
    <My.Layout 
      entity={entity}
      slices={slices}
      headerProps={{ showOverlayGradient: true, variant: 'light' }}
      className={className}
    />
  )
};
