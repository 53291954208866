import { breakpoints, theme, ThemeOverrides } from "@shapeable/theme";
import { Color } from "@shapeable/types";
import { css } from "styled-components";

// export const YELLOW = '#00B482';
export const MARINE_GREEN = '#00B482';
export const MARINE_GREEN_LIGHT = '#00c28c';
// export const YELLOW_ORANGE = '#F79616';


export const themeOverrides: ThemeOverrides = {
  font: {
    family: {
      serif: "Cormorant, georgia, serif",
      sans: 'Inter, sans-serif',
      heading: 'Inter, sans-serif',
      copy: 'Inter, sans-serif',
    }
  },
  light: {
    color: {

      // darkBackground: '#202124',
      // lightBackground: '#F8F8F8',
  
      midBrand: MARINE_GREEN,
      primary: MARINE_GREEN,
  
      buttonHover: MARINE_GREEN,
      linkHover: MARINE_GREEN_LIGHT,
      link: MARINE_GREEN,
      // linkInvertedHover: MARINE_GREEN,
      // linkActive: MARINE_GREEN,
  
      // menuItemLinkHover: MARINE_GREEN, 
      // menuItemLinkActive: MARINE_GREEN, 
      // invertedMenuItemLinkHover: YELLOW_DARK, 
  
    },
  },
  dark: {
    color: {
      link: MARINE_GREEN,
      buttonHover: MARINE_GREEN,
      linkHover: MARINE_GREEN_LIGHT,
    }
  }
};


/* 
--------------------

  Layout Styles
  
  NB: This is the appropriate place to override styling for this specific site, based on BEM-style class name targets 
  
--------------------
*/

export const LayoutStyles = breakpoints({
  base: css`

    .shp--slice-intro {
      font-size: 1.75em;
      text-align: left;
      line-height: 1.25em;
    }

    .shp--entity-value-pill {
      background-color: transparent;
    }

    .shp--slice-content-block-links {
      .shp--button {
        &:hover {
          color: ${theme.COLOR('bright')};
          border-color: ${theme.COLOR('bright')};
          ${theme.FILL('bright')};
        }
      }
    }

    .shp--slice-layout-content-image {
      
      .shp--button {
        color: ${theme.COLOR('bright')};
        border-color: ${theme.COLOR('bright')};
      }
    }

  `,
  tablet: css`
    .shp--slice-intro {
      font-size: 2em;
      text-align: left;
      line-height: 1.25em;
    }
  `,
  desktop: css`
    
  `,
});