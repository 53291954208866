import { 
  COLOR_WARM_GREY,
  SliceImageBlocks,
  createSliceLayout,
} from '@shapeable/ui';
import { ProductGrid } from '../entities/product-grid';
import { SliceProductBenefits } from '../entities/slice-product-benefits';
import { SliceProductIndustries } from '../entities/slice-product-industries';

// Augment standard site layouts here by exporting additional or replacement layouts.
// (simply use the same key to replace any existing slice layouts).

export const SliceLayoutBenefits = createSliceLayout(
  SliceProductBenefits, {
    verticalPadding: 'large',
  }
);

export const SliceLayoutProductIndustries = createSliceLayout(
  SliceProductIndustries, {
    verticalPadding: 'small',
    boundary: 'content',
    defaultBackgroundColor: COLOR_WARM_GREY,
  }
);

export const SliceLayoutProductGrid = createSliceLayout(
  ProductGrid, {
    verticalPadding: 'small',
    boundary: 'content',
    props: {
      desktopAutoColumns: 2,
      tabletAutoColumns: 2,
    }
  }
);

export const SliceLayoutKeyPillars = createSliceLayout(
  SliceImageBlocks, {
    verticalPadding: 'small',
    props: {
      landscapeAutoColumns: 1,

    }
  }
);